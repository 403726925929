<template>
  <div class="chartNum">
    
    <div class="box-item">
     <ul class="box-items">
        <li
        :class="{ 'number-item': !isNaN(item), 'mark-item': isNaN(item), 'mr-item':index%3 == 1 }"
        v-for="(item, index) in orderNum"
        :key="index"
      >
        <span v-if="!isNaN(item)">
          <i ref="numberItem">0123456789</i>
        </span>
        <span class="comma" v-else>{{ item }}</span>
      </li>
     </ul>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      orderNum: ["0", "0", "0", "0", "0", "0", "0", "0", "0", "0"], // 默认订单总数
    };
  },
  mounted() {
    this.$nextTick(() => {
      var num = 1619100000 + Math.ceil(Math.random() * 99999999);
      this.toOrderNum(num); // 这里输入数字即可调用
      this.setNumberTransform();
      setInterval(() => {
        var num = 1619100000 + Math.ceil(Math.random() * 99999999);
        this.toOrderNum(num); // 这里输入数字即可调用
        this.setNumberTransform();
      }, 2000);
    });
  },
  methods: {
    // 设置文字滚动
    setNumberTransform() {
      const numberItems = this.$refs.numberItem; // 拿到数字的ref，计算元素数量
      const numberArr = this.orderNum.filter((item) => !isNaN(item));
      // 结合CSS 对数字字符进行滚动,显示订单数量
      for (let index = 0; index < numberItems.length; index++) {
        const elem = numberItems[index];
        elem.style.transform = `translate(0%, -${numberArr[index] * 10}%)`;
      }
    },
    // 处理总订单数字
    toOrderNum(num) {
      num = num.toString();
      this.orderNum = num.split(""); // 将其便变成数据，渲染至滚动数组

    },
  },
};
</script>
<style scoped lang="less">
/*订单总量滚动数字设置*/
.box-item {
  display: flex;
  align-items: center;
  position: relative;
  width:657px;
  height:124px;
  font-size:26px;
  text-align: center;
  list-style: none;
  color: #2d7cff;
  writing-mode: vertical-lr;
  //text-orientation: upright;
  /*文字禁止编辑*/
  -moz-user-select: none; /*火狐*/
  -webkit-user-select: none; /*webkit浏览器*/
  -ms-user-select: none; /*IE10*/
  -khtml-user-select: none; /*早期浏览器*/
  user-select: none;
  /* overflow: hidden; */
}
.box-items {
  position: absolute;
  top: -28px;
}
/* 默认逗号设置 */
.mark-item {
  width: 10px;
  height: 100px;
  margin-right: 5px;
  line-height: 10px;
  font-size: 36px;
  position: relative;
  & > span {
    position: absolute;
    width: 100%;
    bottom: 0;
    writing-mode: vertical-rl;
    text-orientation: upright;
  }
}
.mr-item {
    margin-right: 20px !important;
    &:after {
        content:'';
        position: absolute;
        right: -30px;
        top: 46px;
        width: 28px;
        height: 42px;
        background: url('../../assets/images/game/black_casion_a001_title_fuhao.png') no-repeat;
    }
}
/*滚动数字设置*/
.number-item {
    display: flex;
    position: relative;
    width:50px;
    height:79px;
    line-height: 79px;
    list-style: none;
    background: url('../../assets/images/game/black_casion_a001_title_bg2.png') 100% no-repeat;
    background-size: cover;
    font-weight:700;
    color:#690000;
    font-size:62px;
    text-align:center;
    & > span {
        position: relative;
        display: inline-block;
        margin-right: 10px;
        width:50px;
        height:79px;
        line-height: 79px;
        writing-mode: vertical-rl;
        text-orientation: upright;
        overflow: hidden;
        background-color: transparent;
        border-radius: 6px;
        &:after{
            content:'';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index:1;
        }
         
        & > i {
            position: relative;
            z-index:2;
            background-color: transparent;
            font-style: normal;
            position: absolute;
            width:50px;
            top: -8px;
            left: 13px;
            transition: transform 1s ease-in-out;
            letter-spacing: 10px;
            font-weight:bolder;
            color:#690000;
        }
    }
}
.number-item:last-child {
  margin-right: 0;
}
</style>
